import { Component, Input, OnInit } from '@angular/core';
import { LhcDataService} from '../../lib/lhc-data.service';
import { WindowService} from '../../lib/window.service';
import { LhcTranslationService } from 'src/lib/lhc-translation.service';
@Component({
  selector: 'lhc-group-vertical',
  templateUrl: './lhc-group-vertical.component.html',
  styleUrls: ['./lhc-group-vertical.component.css']
})
export class LhcGroupVerticalComponent {

  @Input() item: any;

  viewMode = "";
  translation: any;

  constructor(
    private winService: WindowService,
    public lhcDataService: LhcDataService,
  ) {
    this.translation = LhcTranslationService.translation;
    
    winService.windowWidth.subscribe(updatedWidth => {
      this.viewMode = winService.getViewMode();
    });
  }

}
