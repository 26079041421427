<ng-container *ngIf="item">
  <mat-form-field appearance="outline" class="fuse-mat-dense">
    <mat-label>{{item._placeholder}}</mat-label>
    <input matInput    
           id="{{item._elementId}}"
           [disabled]="item._readOnly"
           [ngModel]="item.value"
           (ngModelChange)="onModelChange($event)"
           aria-labelledby="label-{{ item._elementId }}" 
             attr.aria-required="{{ item._answerRequired }}"
           [attr.aria-required]="item._answerRequired ? 'true' : 'false'"
           type="text"
           (blur)="lhcDataService.activeRowOnBlur(item)"
           (focus)="lhcDataService.setActiveRow(item)">
  </mat-form-field>
</ng-container>



