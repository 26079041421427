import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LhcOptionsService {

  /* default option settings for lforms */
  static options = {
    displayScoreWithAnswerText: false
  };

  constructor() { }
  
}
