<ng-container *ngIf="item">
  <mat-form-field appearance="outline">
    <textarea matInput
              id="{{ item._elementId }}"
              [disabled]="item._readOnly"
              [ngModel]="item.value"
              (ngModelChange)="onModelChange($event)"
              placeholder="{{ item._placeholder }}"
              [aria-labelledby]="'label-' + item._elementId"
              [aria-required]="item._answerRequired"
              (focus)="lhcDataService.setActiveRow(item)">
    </textarea>
  </mat-form-field>
</ng-container>
