<ng-container *ngIf="item">
  <mat-radio-group
    [ngModel]="radioValue"
    (ngModelChange)="onModelChange($event)"
    [disabled]="item._readOnly"
    [name]="'radiogroup_' + item._elementId"
    [aria-labelledby]="'label-' + item._elementId"
    (click)="lhcDataService.setActiveRow(item)"
  >
    <mat-radio-button [value]="true" [(ngModel)]="booleanModels[0]" [id]="item._elementId + 'true'">Yes</mat-radio-button>
    <mat-radio-button [value]="false" [(ngModel)]="booleanModels[1]" [id]="item._elementId + 'false'">No</mat-radio-button>
    <mat-radio-button [value]="null" [(ngModel)]="booleanModels[2]" [id]="item._elementId + 'null'">Not Answered</mat-radio-button>
  </mat-radio-group>
</ng-container>
