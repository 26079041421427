<form *ngIf="lhcFormData" class="lhc-form lf-template-table novalidate {{viewModeClass}}" autocomplete="off"
  #lhcFormContainer>

  <div class="mat-title"
            appearance='raised'>
    <div>
      <span id="label-{{ lhcFormData.code }}">{{lhcFormData.name
        || lhcFormData.shortName}}</span>
    </div>
    <!-- code -->
    <span *ngIf="lhcFormData.templateOptions.showQuestionCode && lhcFormData.codeList && lhcFormData.codeList.length>0">
      <span *ngFor="let code of lhcFormData.codeList">
        <span *ngIf="code.code">
          <a *ngIf="code.system == 'http://loinc.org'" href="{{ 'https://loinc.org/' + code.code}}" target="_blank"
            rel="noopener noreferrer">
            [{{ code.code }}]
          </a>
          <span *ngIf="code.system != 'http://loinc.org'" class="lhc-item-code">[{{ code.code }}]</span>
        </span>
      </span>
    </span>
  </div>

  <!--form body-->
  <div>
    <div>
      <!--check form level questionLayout for matrix and horizontal layouts-->
      <ng-container *ngIf="lhcFormData.templateOptions.displayControl"
        [ngSwitch]="lhcFormData.templateOptions.displayControl.questionLayout">
        <ng-container *ngSwitchCase="'horizontal'">
          <lhc-group-horizontal [item]="lhcFormData" [formLevel]='true'></lhc-group-horizontal>
        </ng-container>
        <ng-container *ngSwitchCase="'matrix'">
          <lhc-group-matrix [item]="lhcFormData" [formLevel]='true'></lhc-group-matrix>
        </ng-container>
        <!-- vertical -->
        <ng-container *ngSwitchDefault>
          <!-- data row, for each item -->
          <!-- check each top level item's questionLayout -->
          <ng-container *ngIf="lhcFormData.items">
            <ng-container *ngFor="let item of lhcFormData.items; trackBy: lhcDataService.trackByElementId">
              <lhc-item
                *ngIf="item && lhcDataService.targetShown(item) && !item._isHiddenFromView && !lhcDataService.isSubsequentHorizontalTableGroupItem(item)"
                [item]="item" class="{{lhcDataService.getItemClassList(item, viewMode)}}"
                id="item-{{ item._elementId }}">
              </lhc-item>
              <!--header item-->
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>