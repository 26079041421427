import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LhcTranslationService {

  /* default translation settings for lforms */
  static translation = {
    selectOne: 'Select one',
    typeValue: 'Type a value',
    typeNumber: 'Type a number',
    addAnother: 'Add another',
    addAnotherRow: 'Add another row of',
    instructions: 'instructions',
    searchForValue: 'Search for value',
    searchForValues: 'Search for values',
    searchForOrTypeValue: 'Search for or type a value',
    searchForOrTypeValues: 'Search for or type values',
    selectOneOrMore: 'Select one or more',
    selectOneOrTypeValue: 'Select one or type a value',
    selectOneOrMoreOrTypeValue: 'Select one or more or type a value',
    mustBeTimeValue: 'must be a time value',
    mustBeBoolean: 'must be a boolean (true/false)',
    mustBeDecimal: 'must be a decimal number',
    mustBeInteger: 'must be an integer number',
    mustBeYear: 'must be a numeric value of year',
    mustBeMonth: 'must be a numeric value of month',
    mustBeDay: 'must be a numeric value of day',
    mustBeUrl: 'must be a valid URL',
    mustBeEmail: 'must be a valid email address',
    mustBePhone: 'must be a valid phone number',
  };

  constructor() {
    window['translation'] = LhcTranslationService.translation;
    window.dispatchEvent(new CustomEvent('translationReady'));
  }
  
}
