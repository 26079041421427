import { Component, OnInit, Input, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { LhcDataService} from '../../lib/lhc-data.service';
import { WindowService} from '../../lib/window.service';
import { LhcTranslationService } from 'src/lib/lhc-translation.service';

@Component({
  selector: 'lhc-item',
  templateUrl: './lhc-item.component.html',
  styleUrls: ['./lhc-item.component.css'],
})
export class LhcItemComponent {

  @Input() item;

  viewMode = "";
  translation: any;

  constructor(
    private winService: WindowService,
    public lhcDataService: LhcDataService,
  ) {
    this.translation = LhcTranslationService.translation;

    winService.windowWidth.subscribe(updatedWidth => {
      this.viewMode = winService.getViewMode();
    });
  }

}
