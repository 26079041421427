import { OnChanges, Component, Input } from '@angular/core';
import { LhcDataService } from '../../lib/lhc-data.service';

@Component({
  selector: 'lhc-item-boolean',
  templateUrl: './lhc-item-boolean.component.html',
  styleUrls: ['./lhc-item-boolean.component.css']
})
export class LhcItemBooleanComponent implements OnChanges {
  @Input() item: any;
  booleanModels: boolean[] = new Array(3);

  constructor(public lhcDataService: LhcDataService) {}

  ngOnChanges(changes) {
    this.setInitialValue();
  }

  onModelChange(value) {
    let prevValue = this.item.value;
    this.item.value = value;
    this.lhcDataService.onItemValueChange(this.item, this.item.value, prevValue);
  }

  setInitialValue(): void {
    if (this.item) {
      let booleanValue = this.item.value;
      if (booleanValue === true) {
        this.booleanModels[0] = true;
      } else if (booleanValue === false) {
        this.booleanModels[1] = true;
      } else if (booleanValue === undefined || booleanValue === null) {
        this.booleanModels[2] = true;
      }
    }
  }
}
