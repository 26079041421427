<ng-container *ngIf="item">  
  <div class="lhc-validation-popover" *ngIf="item._hasValidation">
    <mat-list>
      <mat-list-item *ngFor="let error of item._validationErrors">
        <div class="error-content">
          <div>
            <span class="lhc-error-item">{{item.question}} </span>
            <span class="lhc-error-msg">{{error}}</span>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <!-- hidden item.value -->
  <div class="lhc-watched-value">{{value}}</div>
</ng-container>
